import * as React from "react";
import styled, { keyframes } from "styled-components";
import { navigate } from "gatsby"
import {shortText} from '../../constants'


const Accolade = (props: { title: string; text: string; img: string}
) => {

    const {title, text, img} = props;

  return (
    <AccoladeContainer>
    <Image src = {img} alt = "accomplishment medal"/>
    <Title> {title} </Title>
     <Text>
         {text}
     </Text>
    </AccoladeContainer>
  );
};

/*--------------Styling-----------------*/

const AccoladeContainer = styled.div`
    margin: 50px auto;
    width: 80%;
    text-align:center;
    @media (max-width: 1200px) {
        margin: 30px auto;
      }
`

const Image = styled.img`
    margin:auto;
    width: 70%;
    max-width: 300px;
`

const Text = styled.div`
    font-weight: 200;
    line-height: 28px;
`

const Title = styled.div`
    font-size :28px;
    font-weight: bold;
    margin: 20px auto;
`


export default Accolade;
