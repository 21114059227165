import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { theme } from "../../constants";



const ButtonLinkComponent = (props: {
  text: string, to: string, style?: object, state?: object
}) => {

    const {text,to, style, state} = props;

  return (
    <ButtonLink state = {state} to ={to} style = {{...style}}>{text}</ButtonLink>
  );
};

/*--------------Styling-----------------*/

export const ButtonLink = styled(Link)`
height: 40px;
width: 150px;
cursor:pointer;
font-size: 16px;
color : white;
display:flex;
align-items:center;
justify-content:center;
background: ${theme.primaryColor};
font-weight: 400;
cursor:pointer;
text-decoration: none;
margin:auto;
margin-top: 30px;
`


export default ButtonLinkComponent;
