import * as React from "react";
import styled from "styled-components";
import "../../styles/global.css";
import { Link } from "gatsby";


const Service = (props: {
    title: string, img: any, alt: string, id: string
}) => {

    const {title, img, alt, id} = props;

  return (
    <ServiceContainer >
        <Link to = '/services' state={{ service: id }}>
          <Image src = {img} alt = {alt}/>
        </Link>
        <Title> {title} </Title>
    </ServiceContainer>
  );
};

/*--------------Styling-----------------*/

const ServiceContainer = styled.div`
    max-width : 400px;
    margin-top:60px;
    margin:auto;
    text-decoration:none;
    color: black;
    display: block;
`

const Image = styled.img`
  width: 100%;
  max-height: 250px;
`
const Title = styled.h2`
font-weight: 400;
color: 4f4f4f;
text-align: center;
font-size: 28px
`;

export default Service;
