import React, { useEffect } from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import OverlayContent from "../components/Banner/OverlayContent";
import ContactBannerImg from "../images/homebanner.jpeg";
import Accolade from "../components/Accolade";
import {
  homeBannerText,
  covidText,
  covidText2,
  homeText1,
  homeText2,
  accoladeText1,
  accoladeText2,
  accoladeText3,
} from "../constants";
import Grid from "@material-ui/core/Grid";
import HomeImageSource from "../images/service.jpeg";
import AccoladeIcon from "../images/medal.png";
import HomeService from "../components/HomeService";
import WeddingsImg from "../images/wedding.jpg";
import PromImg from "../images/prom.jpeg";
import NightOutImg from "../images/nightout.jpeg";
import BirthdayImg from "../images/birthday.jpeg";
import CasinoImg from "../images/casino.jpeg";
import AirportImg from "../images/airport.jpeg";
import CorporateImg from "../images/corporate.jpeg";
import WineImg from "../images/wine.jpeg";
import CovidImg from "../images/covid.jpeg";
import ButtonLink from "../components/ButtonLink";
import Hidden from "@material-ui/core/Hidden";
import scrollTo from "gatsby-plugin-smoothscroll";
import SEOHelmet from "../components/SEOHelmet";

const IndexPage = ({ location }) => {
  useEffect(() => {
    if (location?.state?.scrollToTop) {
      scrollTo("#top");
    }
  }, []);

  const subTitle = () => (
    <SubtitleContainer>
      {homeBannerText}
      {/* <ButtonLink to={'/contact'} text = {'Contact Us'}/> */}
    </SubtitleContainer>
  );

  return (
    <HomeContainer id="top">
      <SEOHelmet
        title="Memories Limousine | Durham and GTA Limousine Service"
        description="Memories limousine services. Limousine company operating out of Whitby serving the Durham region and the rest of the GTA for 20+ years"
      />
      <Header />
      <Banner showOverlay img={ContactBannerImg}>
        <OverlayContent title={"Memories Limousine"} subTitle={subTitle} />
      </Banner>
      <ContentContainer>
        <ThinTitle style={{ color: "black", fontWeight: 200 }}>
          We Offer Services for all of your Limousine Needs:
        </ThinTitle>
        <ServiceRow>
          <Grid container spacing={1}>
            {/* The ids for these containers are for whatever row the service is on. Thats why prom has an id of wedding etc.. */}
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <HomeService
                title={"Weddings"}
                alt="Wedding Party"
                img={WeddingsImg}
                id="wedding"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <HomeService
                title={"Proms and Graduation"}
                alt="Prom Party"
                img={PromImg}
                id="prom"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <HomeService
                title={"Night Out"}
                alt="People partying during a night out"
                img={NightOutImg}
                id="nightOut"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <HomeService
                title={"Birthdays"}
                alt="People celebrating birthday"
                img={BirthdayImg}
                id="birthday"
              />
            </Grid>
            <Hidden mdDown>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <HomeService
                  title={"Events and Concerts"}
                  alt="Casino chips on table"
                  img={CasinoImg}
                  id="casino"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <HomeService
                  title={"Airport Dropoff"}
                  alt="Suitcase dropped off at airport"
                  img={AirportImg}
                  id="airport"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <HomeService
                  title={"Wine Tour"}
                  alt="Wine glass on table"
                  img={WineImg}
                  id="wine"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <HomeService
                  title={"Corporate Function"}
                  alt="Professional Event"
                  img={CorporateImg}
                  id="corporate"
                />
              </Grid>
            </Hidden>
          </Grid>
        </ServiceRow>
        <ContactSection style={{ background: "#40596a" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ImageContainer>
                <Image alt="people celebrating in limo" src={HomeImageSource} />
              </ImageContainer>
            </Grid>
            <Grid item xs={12} md={6}>
              <ContactTitle>Limousine and Chauffeur Services</ContactTitle>
              <ContactText>{homeText1}</ContactText>
              <br />
              <ContactText>{homeText2}</ContactText>
              <ButtonLink
                state={{ scrollToTop: true }}
                to={"/contact"}
                text={"Contact Us"}
                style={{ marginTop: 50 }}
              />
            </Grid>
          </Grid>
        </ContactSection>
        <AccoladeRow>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Accolade
                text={accoladeText1}
                title={"20 Years of Experience"}
                img={AccoladeIcon}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Accolade
                text={accoladeText2}
                title={"Reliable Chauffeurs"}
                img={AccoladeIcon}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Accolade
                text={accoladeText3}
                title={"Satisfaction Guaranteed"}
                img={AccoladeIcon}
              />
            </Grid>
          </Grid>
        </AccoladeRow>
        <ContactSection>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CovidTitle>Covid-19 Protocol</CovidTitle>
              <AboutSecondText>{covidText} </AboutSecondText>
              <br />
              <AboutSecondText>{covidText2} </AboutSecondText>
            </Grid>

            <Grid item xs={12} md={6}>
              <ImageContainer>
                <Image src={CovidImg} alt="covid safety protocol" />
              </ImageContainer>
            </Grid>
          </Grid>
        </ContactSection>
      </ContentContainer>
      <Footer />
    </HomeContainer>
  );
};

/*--------------Styling-----------------*/

const HomeContainer = styled.div``;

const SubtitleContainer = styled.div`
  font-size: 16px;
`;
const ContentContainer = styled.div`
  margin: auto;
  margin-top: 75px;
`;

const AccoladeRow = styled.div`
  max-width: 1500px;
  margin: auto;
`;

const ServiceRow = styled.div`
  margin: 75px auto;
  width: 90%;
`;
const ThinTitle = styled.h2`
  font-weight: 300;
  font-size: 28px;
  color: white;
  text-align: center;
`;

const Text = styled.div`
  font-weight: 100;
  line-height: 30px;
  margin: auto;
  max-width: 800px;
  font-size: 16px;
  padding: 0px 20px;
  color: gray;
  width: 95%;
`;

const Image = styled.img`
  max-width: 800px;
  width: 100%;
  @media (max-width: 960px) {
    width: 95%;
`;

const ImageContainer = styled.div`
  text-align: center;
  margin-top: 40px;
`;

const AboutSecondText = styled(Text)`
  color: lightgrey;
  text-align: center;
  font-size: 21px;
`;

const ContactSection = styled.div`
background:#383838;
padding: 50px;
@media (max-width: 960px) {
  padding: 50px 0;
}
color: 
text-align:left;
`;

const CovidTitle = styled.h2`
  color: white;
  text-align: center;
  font-weight: 500;
`;

const ContactTitle = styled.h2`
  color: white;
  text-align: center;
  font-weight: 500;
`;

const ContactText = styled(Text)`
  color: white;
  text-align: center;
  font-size: 21px;
`;

export default IndexPage;
